import React from "react";

export const Logo = () => (
  <svg
    id="logo"
    width="8rem"
    height="8rem"
    viewBox="0 0 114 114"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="114" height="114" fill="#3DDC97" />
    <path
      d="M21.0608 65.1762C19.0689 65.1762 17.5162 64.638 16.4027 63.5616C15.2892 62.4853 14.7325 60.9759 14.7325 59.0334V51.8886H19.1122V58.9035C19.1122 59.8562 19.2792 60.549 19.6133 60.982C19.9597 61.4151 20.4546 61.6316 21.0979 61.6316C21.7413 61.6316 22.23 61.4151 22.564 60.982C22.9104 60.549 23.0836 59.8562 23.0836 58.9035V51.8886H27.3891V59.0334C27.3891 60.9759 26.8324 62.4853 25.7189 63.5616C24.6054 64.638 23.0527 65.1762 21.0608 65.1762Z"
      fill="white"
    />
    <path
      d="M35.6508 48.7072C36.8509 48.7072 37.8964 48.9051 38.7872 49.301C39.6779 49.6969 40.3646 50.266 40.8471 51.0084C41.3296 51.7507 41.5709 52.6167 41.5709 53.6065C41.5709 54.5963 41.3296 55.4623 40.8471 56.2046C40.3646 56.947 39.6779 57.5161 38.7872 57.912C37.8964 58.3079 36.8509 58.5059 35.6508 58.5059H33.7765V61.6979H29.3967V48.7072H35.6508ZM35.3725 55.1283C35.9663 55.1283 36.4117 54.9984 36.7086 54.7385C37.0056 54.4664 37.154 54.089 37.154 53.6065C37.154 53.124 37.0056 52.7528 36.7086 52.493C36.4117 52.2208 35.9663 52.0847 35.3725 52.0847H33.7765V55.1283H35.3725Z"
      fill="white"
    />
    <path
      d="M54.6061 64.8793L54.569 58.9592L51.7481 63.7101H49.8181L46.9972 59.1634V64.8793H43.0072V51.8886H46.6075L50.8388 58.8107L54.9587 51.8886H58.559L58.5961 64.8793H54.6061Z"
      fill="white"
    />
    <path
      d="M67.5244 65.1762C66.1264 65.1762 64.8706 64.8854 63.7571 64.304C62.6436 63.7225 61.7714 62.9183 61.1404 61.8914C60.5095 60.8521 60.194 59.683 60.194 58.3839C60.194 57.0848 60.5095 55.9219 61.1404 54.895C61.7714 53.8557 62.6436 53.0454 63.7571 52.4639C64.8706 51.8824 66.1264 51.5916 67.5244 51.5916C68.9225 51.5916 70.1782 51.8824 71.2917 52.4639C72.4052 53.0454 73.2775 53.8557 73.9084 54.895C74.5394 55.9219 74.8549 57.0848 74.8549 58.3839C74.8549 59.683 74.5394 60.8521 73.9084 61.8914C73.2775 62.9183 72.4052 63.7225 71.2917 64.304C70.1782 64.8854 68.9225 65.1762 67.5244 65.1762ZM67.5244 61.6316C68.0688 61.6316 68.5575 61.5017 68.9905 61.2419C69.4359 60.9697 69.7885 60.5923 70.0483 60.1098C70.3082 59.6149 70.4381 59.0396 70.4381 58.3839C70.4381 57.7282 70.3082 57.1591 70.0483 56.6766C69.7885 56.1817 69.4359 55.8043 68.9905 55.5445C68.5575 55.2723 68.0688 55.1362 67.5244 55.1362C66.9801 55.1362 66.4852 55.2723 66.0398 55.5445C65.6068 55.8043 65.2603 56.1817 65.0005 56.6766C64.7407 57.1591 64.6108 57.7282 64.6108 58.3839C64.6108 59.0396 64.7407 59.6149 65.0005 60.1098C65.2603 60.5923 65.6068 60.9697 66.0398 61.2419C66.4852 61.5017 66.9801 61.6316 67.5244 61.6316Z"
      fill="white"
    />
    <path
      d="M81.2882 65.1762C80.2118 65.1762 79.1602 65.0587 78.1333 64.8236C77.1188 64.5761 76.2837 64.2483 75.628 63.84L77.0384 60.648C77.657 61.0068 78.3498 61.2975 79.1169 61.5202C79.884 61.7306 80.6201 61.8357 81.3253 61.8357C81.9315 61.8357 82.3646 61.78 82.6244 61.6687C82.8842 61.545 83.0141 61.3656 83.0141 61.1305C83.0141 60.8583 82.8409 60.6542 82.4945 60.5181C82.1604 60.382 81.6037 60.2335 80.8242 60.0727C79.8221 59.8624 78.987 59.6397 78.3189 59.4046C77.6508 59.1572 77.0693 58.7613 76.5744 58.2169C76.0795 57.6601 75.8321 56.9116 75.8321 55.9714C75.8321 55.1548 76.061 54.4125 76.5187 53.7444C76.9765 53.0763 77.657 52.5505 78.5601 52.1669C79.4757 51.7834 80.583 51.5916 81.882 51.5916C82.7728 51.5916 83.6451 51.6906 84.4987 51.8886C85.3648 52.0741 86.1257 52.3525 86.7814 52.7237L85.4638 55.8971C84.1894 55.2538 82.9832 54.9321 81.8449 54.9321C80.7191 54.9321 80.1561 55.2043 80.1561 55.7487C80.1561 56.0085 80.3232 56.2064 80.6572 56.3425C80.9913 56.4662 81.5418 56.6023 82.3089 56.7508C83.2987 56.9364 84.1338 57.1529 84.8142 57.4003C85.4947 57.6354 86.0824 58.0251 86.5773 58.5695C87.0845 59.1139 87.3381 59.8562 87.3381 60.7965C87.3381 61.613 87.1093 62.3554 86.6515 63.0234C86.1937 63.6792 85.5071 64.205 84.5915 64.6009C83.6884 64.9844 82.5873 65.1762 81.2882 65.1762Z"
      fill="white"
    />
    <path
      d="M91.281 55.2847H87.4766V51.8886H99.4652V55.2847H95.6607V64.8793H91.281V55.2847Z"
      fill="white"
    />
  </svg>
);
