import "./App.scss";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

import balanceText from "balance-text";
import gsap from "gsap";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import Splitting from "splitting";

import { Circles } from "./components/Circles";
import { Logo } from "./components/Logo";

export function App() {
  const pRef = useRef<HTMLParagraphElement>(null);

  useLayoutEffect(() => {
    if (pRef.current) {
      balanceText(pRef.current);
      Splitting({ target: "p" });

      gsap.set(pRef.current.querySelectorAll(".char"), {
        y: "-50%",
        opacity: 0,
      });

      gsap.set(".logo", {
        opacity: 0,
        y: "-50%",
      });

      gsap.set(".button", {
        opacity: 0,
        y: "50%",
      });
    }
  }, [pRef.current]);

  useEffect(() => {
    if (pRef.current) {
      pRef.current.querySelectorAll(".char").forEach((char, i) => {
        gsap.to(char, {
          y: "0%",
          opacity: 1,
          delay: 1 + i * 0.014,
          ease: "Power3.easeOut",
        });
      });

      gsap.to(".logo, .button", {
        duration: 2,
        opacity: 1,
        delay: 2,
        y: "0%",
        ease: "Power3.easeInOut",
      });
    }
  }, [pRef.current]);

  return (
    <div className="App">
      <Circles />
      <div className="wrap">
        <main>
          <div className="logo">
            <Logo />
          </div>

          <p ref={pRef}>We think, design and build apps and experiences.</p>

          <a href="mailto:as@upmost.dk" className={"button"}>
            Contact us for inquiries
          </a>
        </main>
      </div>
    </div>
  );
}
