import gsap, { Sine } from "gsap";
import React, { FC, useEffect } from "react";

function random(min: number, max: number) {
  if (max === null) {
    max = min;
    min = 0;
  }

  return Math.random() * (max - min) + Number(min);
}

export const Circles: FC = () => {
  useEffect(() => {
    const circles: NodeListOf<SVGElement> = document.querySelectorAll("g > *");

    function animateCircle(circle: SVGElement) {
      gsap.to(circle, {
        duration: random(4, 15),
        zIndex: random(0, 3),
        attr: {
          cx: random(0, 1440),
          cy: random(0, 628),
        },
        ease: Sine.easeInOut,
        onComplete: function () {
          animateCircle(circle);
        },
      });
    }

    circles.forEach(animateCircle);
  }, []);

  return (
    <div id="circles">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1440 628"
        preserveAspectRatio="xMidYMid slice"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <ellipse
            className="elm"
            cx="243"
            cy="461"
            rx="243"
            ry="167"
            fill="#4A92C7"
          />
          <circle className="elm" cx="224" cy="121" r="315" fill="#AB478F" />
          <circle
            className="elm"
            cx="1358.5"
            cy="234.5"
            r="272.5"
            fill="#6F47AB"
          />
        </g>
      </svg>
    </div>
  );
};
